import { memo, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { Path } from '@/shared/constants/links';
import { ConnectionQualityModal } from '@/modules/mobile-communication-quality/quality-modal/quality-modal';
import { FaqCard } from './faq-card';
import { ConnectionProblems } from './connection-problems';
import { MobileApp } from './mobile-app';
import { Socials } from './socials';
import styles from './footer-cards.module.scss';

export const FooterCardsBlock = memo(() => {
  const { isDesktopMax } = useMediaQuery();
  const [showConnectionQualityModal, setShowConnectionQualityModal] = useState(false);
  const router = useRouter();
  const mobileAppPage = router.pathname.includes(Path.faq.app);
  const isFaqPage = router.pathname.includes(Path.faq.root) && !mobileAppPage;

  const withoutFooter = [
    Path.skatepark,
    Path.litres,
    Path.promotions,
    Path.services.sberbox,
    Path.services.viju,
    Path.kaspersky,
    Path.inviteFriend,
    Path.inviteFriend,
    Path.faq.app,
    Path.services.premier,
    Path.volnaSale
  ].includes(router.pathname);

  const faqPageStyle = isDesktopMax && isFaqPage;

  const handleCloseConnectionQualityModal = () => setShowConnectionQualityModal(false);

  if (withoutFooter) {
    return null;
  }

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper__faqPage]: isFaqPage,
        [styles.resetHeight]: withoutFooter,
      })}
    >
      {!isFaqPage && (
        <>
          <FaqCard className={styles.faq} />
          <ConnectionProblems setShowModal={setShowConnectionQualityModal} className={styles.problemsBlock} />
        </>
      )}

      <MobileApp isFaqPage={faqPageStyle} className={styles.mobileAppBlock} />
      <Socials className={cn(styles.socialBlock, { [styles.socialBlock__faqPage]: isFaqPage })} />

      <ConnectionQualityModal
        showModal={showConnectionQualityModal}
        handleCloseModal={handleCloseConnectionQualityModal}
      />
    </div>
  );
});

FooterCardsBlock.displayName = 'FooterCardsBlock';
