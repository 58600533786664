import React, { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import cn from 'classnames';
import { Button } from '@mantine/core';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { PCLogo } from '@/shared/assets/svg';
import { Path } from '@/shared/constants/links';
import styles from './mobile-app.module.scss';

interface MobileAppProps {
  className?: string
  isFaqPage: boolean;
}

export const MobileApp: FC<MobileAppProps> = ({ className, isFaqPage }) => {
  const { isMobile } = useMediaQuery();

  return (
    <Link href={Path.faq.app} className={cn(className, styles.card, { [styles.card__faqPage]: isFaqPage })}>
      {isMobile && <PCLogo />}
      {isFaqPage && (
        <Image
          className={styles.card__faqPage__phone}
          src='/assets/img/footer/pc_desktop_phone.png'
          alt='phone'
          width={550}
          height={550}
        />
      )}
      <div className={cn(styles.card__textblock, { [styles.card__faqPage__textbloc]: isFaqPage })}>
        {!isFaqPage ? (
          <div className={styles.title}>
            <span className={styles.title__firstWord}>Личный</span>
            <span className={styles.title__secondWord}>кабинет</span>
          </div>
        ) : (
          <div className={styles.card__faqPage__title}>Мобильное приложение</div>
        )}
        <div className={styles.card__info}>
          {isFaqPage ? (
            <ul>
              <li>Оплата связи</li>
              <li>Контроль расходов</li>
              <li>Управление услугами</li>
            </ul>
          ) : (
            <span>Управляй связью</span>
          )}
        </div>
      </div>
      <Button type='button' classNames={{ root: cn(styles.button, { [styles.button__faqPage]: isFaqPage }) }}>
        {isFaqPage ? 'Перейти' : 'Как установить'}
      </Button>
    </Link>
  );
};
