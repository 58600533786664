export const validate = (s?: string) => {
  if (!s) return;
  if (s.endsWith('  ')) return false;
  if (s.startsWith(' ')) return false;
  if (s.length > 256) return false;

  return s[s.length - 1].match(/[а-яА-ЯёЁa-zA-Z0-9]|\/|\.|,|-|\s/);
};

export const formatOnPaste = (s?: string) => {
  let formatted = '';
  for (let i = 0; i < 256; i++) {
    const char = s?.[i];
    if (char && char.match(/[а-яА-ЯёЁa-zA-Z0-9]|\/|\.|,|-|\s/) && validate(formatted + char)) { // TODO убрать двойную проверку регуляркой. Для проверки на длину и пустые символы можно сделать отдельную функцию.
      formatted += char;
    }
  }

  return formatted;
};

export const isValidAddress = (text: string): boolean => {
  const validPattern = /^[а-яА-ЯёЁ0-9/., \\-]*$/;
  
  return validPattern.test(text) || text === '';
};

export const isValidEmail = (text: string): boolean => {
  const validPattern = /^[а-яА-ЯёЁa-zA-Z0-9/.,@ \\-]*$/;
  
  return validPattern.test(text) || text === '';
};

export const emailRegexp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/;
