import Link from 'next/link';
import cn from 'classnames';
import { Button } from '@mantine/core';
import { Path } from '@/shared/constants/links';
import styles from './faq-card.module.scss';

export const FaqCard = ({ className }: { className?: string }) => {

  return (
    <Link href={Path.faq.root} className={cn(className, styles.card)}>
      <div className={styles.info}>
        <h3>Есть вопрос?</h3>
        <p>У нас уже готов ответ</p>
      </div>
      <Button type='button' classNames={{ root: styles.button }}>
        Узнать
      </Button>
    </Link>
  );
};
