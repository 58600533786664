import React, { FC } from 'react';
import cn from 'classnames';
import { Button } from '@mantine/core';
import styles from './connection-problems.module.scss';

interface ConnectionProblemsProps {
  className?: string
  setShowModal: (v: boolean) => void;
}

export const ConnectionProblems: FC<ConnectionProblemsProps> = ({ setShowModal, className }) => {
  const openModal = () => setShowModal(true);

  return (
    <div onClick={openModal} className={cn(styles.card, className)}>
      <div className={styles.info}>
        <h3>Проблемы со связью?</h3>
        <p>Ответь на несколько вопросов, а мы поможем</p>
      </div>
      <Button onClick={openModal} classNames={{ root: styles.button }}      >
        Пройти опрос
      </Button>
    </div>
  );
};
